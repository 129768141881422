import {
  CREATE_ERROR,
  CLEAR_ERROR,
  RequestError
} from "state/actions/error";
import createReducer from "./createReducer";

export default createReducer<RequestError>({
  successType: CREATE_ERROR,
  clearType: CLEAR_ERROR
});
