import React, { useState } from "react";
import { isMicroDepositRequestType } from "utils";
import TrustlyIntegration, {
  TrustlyIntegrationProps,
} from "./TrustlyIntegration";
import TrustlyMicroDepositInstruction from "./TrustlyMicroDepositInstruction";

export const scriptId = "trustlyIFrame";

const TrustlyIntegrationMain = (props: TrustlyIntegrationProps) => {
  const microDepositRequestType = isMicroDepositRequestType(props.requestType);
  const [
    showMicroDepositVerifyInstruction,
    setShowMicroDepositVerifyInstruction,
  ] = useState(microDepositRequestType);

  if (showMicroDepositVerifyInstruction) {
    return (
      <TrustlyMicroDepositInstruction
        onExit={() => props.onSuccess([])}
        onContinue={() => setShowMicroDepositVerifyInstruction(false)}
      />
    );
  }
  return <TrustlyIntegration {...props} />;
};

export default TrustlyIntegrationMain;
