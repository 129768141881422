import { action } from "typesafe-actions";
import { PlaidLog } from "types";

export const LOG_PLAID_EVENT = "LOG_EVENT";

export const logPlaidEvent = (payload: PlaidLog) =>
  action(LOG_PLAID_EVENT, payload);

export const RECEIVED_PLAID_TOKEN = "RECEIVED_PLAID_TOKEN";
export const SET_PLAID_HANDLER = "SET_PLAID_HANDLER";

export const receivePlaidToken = (payload: any) => {
  return action(RECEIVED_PLAID_TOKEN, payload);
};

export const setPlaidHandler = (payload: any) =>
  action(SET_PLAID_HANDLER, payload);
