import { action } from "typesafe-actions";

export const CREATE_ERROR = "CREATE_ERROR";
export const CLEAR_ERROR = "CLEAR_ERROR";

export type RequestError = {
  response?: {
    data: {
      error: { message: string };
    };
    status?: number;
  };
  message?: string;
};

export const setError = (payload: RequestError) =>
  action(CREATE_ERROR, payload);

export const clearError = () => action(CLEAR_ERROR);
