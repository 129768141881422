import { put, takeEvery, call, select } from "redux-saga/effects";
import { PayloadAction } from "typesafe-actions/dist/type-helpers";

import { PlaidAccount, PlaidLog } from "types";
import { LOG_PLAID_EVENT, RECEIVED_PLAID_TOKEN } from "../actions/plaid";
import { setInstitution } from "../actions/institution";

import { selectIAVToken, selectProviderInfo } from "../selectors";

import { addSelectionAccounts } from "../actions/selectionAccounts";

import { getIAVServer, wrapSaga, getSdk } from "./utils";

const plaidAccountsUri = "/auth/plaid/accounts";
const institutionUri = "/auth/plaid/institution";

export const ADMIN_USER_ID = 1000;

const receivedPlaidTokenSaga = wrapSaga(function* ({
  payload,
}: PayloadAction<string, any>) {
  const {
    publicToken,
    metadata: {
      institution,
      account,
      accounts: institutionAccounts,
      link_session_id: linkSessionId,
    },
    type,
    microDeposit,
  } = payload;

  const iavServer = yield getIAVServer();
  const iavToken = yield select(selectIAVToken);
  const { plaidEnv } = yield select(selectProviderInfo);

  const { institution_id: institutionId } = institution;

  if (!microDeposit && institutionId) {
    const { logo, primaryColor } = yield call(
      iavServer.post,
      institutionUri,
      { institutionId: institution.institution_id, type, plaidEnv },
      iavToken
    );

    yield put(
      setInstitution({
        id: institutionId,
        name: institution.name,
        logo,
        primaryColor,
      })
    );
  }

  const { accounts } = yield call(
    iavServer.post,
    plaidAccountsUri,
    {
      publicToken,
      type,
      account: microDeposit ? institutionAccounts[0] : account,
      institution,
      microDeposit,
      plaidEnv,
      linkSessionId,
    },
    iavToken
  );

  if (account?.id || microDeposit) {
    return accounts;
  }

  const fixedAccounts = accounts.map((account: PlaidAccount) => ({
    id: account.account_id,
    ddaType: account.subtype,
    accountNumber: account.account,
    accountNbrMask: account.mask,
    nickName: account.name,
    achRtn: account.routing,
    availBalance: account.balances.available,
    alreadyLinked: account.alreadyLinked,
    iavAccountId: account.account_id,
  }));

  yield put(addSelectionAccounts(fixedAccounts));
});

function* logPlaidEventSaga({ payload }: PayloadAction<string, PlaidLog>) {
  try {
    const sdk = yield getSdk();
    const {
      error,
      metadata,
      requestType,
      startAt,
      status,
      publicToken,
      fspId,
      userId,
      payerId,
      externalDdaId,
      accountId,
    } = payload;
    const fsp = sdk.fsps(fspId);

    const { account, account_id, link_session_id, request_id, institution } =
      metadata;

    const iavRequestData = {
      externalDdaId,
      publicToken,
      userId,
      requestType,
      startAt,
      linkSessionId: link_session_id,
      requestId: request_id,
      institutionName: institution?.name,
      institutionId: institution?.institution_id,
      iavStatus: status,
      errorType: error?.error_type,
      errorCode: error?.error_code,
      errorMessage: error?.error_message,
      accountId: accountId || account_id || account?.id,
    };
    yield call(fsp.payers(payerId).iavRequests.create, iavRequestData, {
      userId,
    });
  } catch (exception) {
    // ignore error
  }
}

function* plaidSaga() {
  yield takeEvery(RECEIVED_PLAID_TOKEN, receivedPlaidTokenSaga);
  yield takeEvery(LOG_PLAID_EVENT, logPlaidEventSaga);
}

export default plaidSaga;
