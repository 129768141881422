import React from "react";

import { Provider } from "react-redux";

import "i18n";
import ThemeContainer from "themes/ThemeContainer";
import Loading from "common/Loading";
import { trackApp } from "analytics";

import { AppProps } from "./types";
import useIdleTimeout from "./useIdleTimeout";
import useApp from "./useApp";
import { IAV_NEW } from "./consts";
import IAVProviderIntegration from "./integration/IAVProviderIntegration";

export type AppStoreProps = AppProps & {
  store: any;
};

const App = trackApp(
  ({ type = IAV_NEW, useNew = false, ...restOfProps }: AppProps) => {
    const { authenticated, authenticating, handleSuccess, handleError } =
      useApp({
        type,
        useNew,
        ...restOfProps,
      });

    const { initCount, env, accountId, refresh } = restOfProps;
    useIdleTimeout({ enabled: type === IAV_NEW || useNew });

    return authenticated ? (
      <IAVProviderIntegration
        onSuccess={handleSuccess}
        onError={handleError}
        type={type}
        initCount={initCount}
        env={env}
        accountId={accountId}
        refresh={refresh}
      />
    ) : authenticating ? (
      <Loading />
    ) : (
      <div />
    );
  }
);

const IAVApp = ({ store, ...props }: AppStoreProps) => (
  <Provider store={store}>
    <ThemeContainer>
      <App {...props} />
    </ThemeContainer>
  </Provider>
);

export default IAVApp;
