import numeral from "numeral";
import {
  AMOUNT_FORMAT,
  PENDING_AUTOMATIC_VERIFICATION_STATUS,
  PENDING_MANUAL_VERIFICATION_STATUS,
  MANUALLY_VERIFIED_VERIFICATION_STATUS,
  IAV,
  MICRODEPOSIT,
} from "consts";

export const formatAmount = (amount: number) =>
  numeral(amount).format(AMOUNT_FORMAT);

export const isMicroDeposit = (status: string) =>
  status === PENDING_AUTOMATIC_VERIFICATION_STATUS ||
  status === PENDING_MANUAL_VERIFICATION_STATUS ||
  status === MANUALLY_VERIFIED_VERIFICATION_STATUS;

export const getErrorMessage = ({ error, t }: { error: any; t: any }) => {
  const errorKey = `error.API_${error.number}`;
  const message = t(errorKey);
  if (message === errorKey) {
    return error.message;
  }
  return message;
};

export const isValidColor = (color?: string) =>
  color && /(^#[0-9A-F]{6}$)|(^#[0-9A-F]{3}$)/i.test(color);

export const isIAV = (type: string) => type === IAV;

export const isMicroDepositRequestType = (requestType: string) =>
  requestType === MICRODEPOSIT;
