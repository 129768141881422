import { getFontProperties } from "./fonts";

const themeBuilder = ({
  primaryColor,
  secondaryColor,
  primaryTextColor,
  secondaryTextColor,
  background,
  fonts,
  iavOverrides = {},
}) => {
  const { fontFamilies, fontUrls, fontNames } = getFontProperties(fonts);

  return {
    primaryColor,
    secondaryColor,
    primaryTextColor,
    secondaryTextColor,
    background,
    fontFamilies,
    fontUrls,
    fonts: {
      default: fontNames.default,
      sans: fontNames.sans || fontNames.default,
      mono: fontNames.mono,
      heading: fontNames.heading || fontNames.default,
    },
    iavOverrides,
  };
};

export default themeBuilder;
