import { AuthSuccess, ExtDdaAccount } from "types";
import {
  AUTHENTICATE_PENDING,
  AUTHENTICATE_SUCCESS,
  AUTHENTICATE_ERROR,
} from "state/actions/auth";

type Auth = {
  authenticated: boolean;
  pending: boolean;
  error: boolean;
  fspId?: number;
  userId?: number;
  payerId?: number;
  iavToken?: string;
  verifiedAccount?: ExtDdaAccount | null;
};

type ReducerParams = {
  type: string;
  payload?: AuthSuccess;
};

const initialState = { authenticated: false, pending: false, error: false };
const authReducer = (
  state = initialState,
  { type, payload }: ReducerParams
): Auth => {
  switch (type) {
    case AUTHENTICATE_PENDING:
      return { ...state, pending: true };
    case AUTHENTICATE_SUCCESS:
      return {
        ...state,
        authenticated: true,
        pending: false,
        ...(payload as object),
      };
    case AUTHENTICATE_ERROR:
      return { authenticated: false, pending: false, error: true };
    default:
      break;
  }
  return state;
};

export default authReducer;
