import { action } from "typesafe-actions";
import { AuthRequest, AuthSuccess } from "types";
export const AUTHENTICATE = "AUTHENTICATE";
export const AUTHENTICATE_PENDING = "AUTHENTICATE_PENDING";
export const AUTHENTICATE_SUCCESS = "AUTHENTICATE_SUCCESS";
export const AUTHENTICATE_ERROR = "AUTHENTICATE_ERROR";
export const UNAUTHORIZED = "UNAUTHORIZED";
export const LOGOUT = "LOGOUT";

export const authenticate = (payload: AuthRequest) =>
  action(AUTHENTICATE, payload);

export const authenticating = () => action(AUTHENTICATE_PENDING);

export const authenticateSuccess = (payload: AuthSuccess) =>
  action(AUTHENTICATE_SUCCESS, payload);

export const authenticateError = () => action(AUTHENTICATE_ERROR);

export const logout = () => action(LOGOUT);
