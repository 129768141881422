import { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import {
  selectFspConfig,
  isErrorAuth,
  isAuthenticated,
  isAuthenticating,
} from "state/selectors";

import { authenticate, logout } from "./state/actions/auth";

import {
  dispatchAnalysis,
  PAGEVIEW_EVENT,
  CONFIRMATION_PAGE,
  ERROR_PAGE,
} from "./analytics";

import { ExtDdaAccount, AppProps } from "./types";
import { IAV_NEW } from "./consts";
import { RequestError } from "./state/actions/error";
import { getErrorMessage } from "./utils";

const useApp = (props: AppProps) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const authenticating = useSelector(isAuthenticating);
  const errorAuth = useSelector(isErrorAuth);
  const fspConfig = useSelector(selectFspConfig);
  const authenticated = useSelector(isAuthenticated);

  const {
    fspId,
    payerId,
    userId,
    accountId,
    ssoToken,
    useNew,
    extDdaId,
    type = IAV_NEW,
    onSuccess,
    onError,
    initCount,
  } = props;

  const handleSuccess = useCallback(
    (accounts: ExtDdaAccount[]) => {
      if (onSuccess) {
        dispatchAnalysis({
          event: PAGEVIEW_EVENT,
          page: CONFIRMATION_PAGE,
        });
        if (type === IAV_NEW) {
          if (accounts && accounts.length > 0) {
            onSuccess(accounts[0]);
          } else {
            onSuccess(null);
          }
        } else {
          onSuccess(accounts);
        }
      }
      dispatch(logout());
    },
    [dispatch, onSuccess, type]
  );

  const handleError = useCallback(
    (error: RequestError) => {
      if (error && onError) {
        dispatchAnalysis({
          event: PAGEVIEW_EVENT,
          page: ERROR_PAGE,
        });
        onError({ ...error, message: getErrorMessage({ error, t }) });
      }
      dispatch(logout());
    },
    [dispatch, onError, t]
  );

  useEffect(() => {
    dispatch(
      authenticate({
        fspId,
        payerId,
        userId,
        accountId,
        ssoToken,
        useNew,
        extDdaId,
        type,
        onError: handleError,
      })
    );
  }, [
    dispatch,
    fspId,
    payerId,
    userId,
    accountId,
    ssoToken,
    useNew,
    extDdaId,
    type,
    initCount,
    handleError,
  ]);
  return {
    authenticating,
    authenticated,
    errorAuth,
    fspConfig,
    handleSuccess,
    handleError,
  };
};

export default useApp;
