import { User } from "types";
export const USER_INFO = "USER_INFO";
export const USER_INTERACTION = "USER_INTERACTION";

export const setUser = (payload: User) => ({
  type: USER_INFO,
  payload
});

export const userInteraction = () => ({
  type: USER_INTERACTION,
});
