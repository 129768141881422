import createMuiTheme, {
  ThemeOptions,
} from "@material-ui/core/styles/createMuiTheme";

export default function createTheme({custom, ...options}: ThemeOptions) {
  return createMuiTheme({
    custom,
    ...options,
  });
}
