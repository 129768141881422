import {StateType} from "typesafe-actions";

import Reducers from "state/reducers";
import {ExtDdaAccount, Optional, TrustlyInfo} from "types";
import {PlaidInfo} from "../types";

export const selectFspConfig = (state: StateType<typeof Reducers>) => {
    return {...state.fspConfig, a2aConfig: {iavProvider: 'trustly'}};
}

export const isAuthenticated = (state: StateType<typeof Reducers>) =>
    state.auth.authenticated;

export const isAuthenticating = (state: StateType<typeof Reducers>) =>
    state.auth.pending;

export const selectAuth = (state: StateType<typeof Reducers>) => state.auth;

export const selectVerifiedAccount = (state: StateType<typeof Reducers>) =>
    state.auth.verifiedAccount;

export const isErrorAuth = (state: StateType<typeof Reducers>) =>
    state.auth.error;

export const selectProviderInfo = (
    state: StateType<typeof Reducers>
): PlaidInfo | TrustlyInfo | null => state.providerInfo;

export const selectInstitution = (state: StateType<typeof Reducers>) =>
    state.institution;

export const selectUserName = (state: StateType<typeof Reducers>) => {
    const {user} = state;
    if (user) {
        const {firstName, middleName, lastName} = user;

        return `${firstName}${middleName ? ` ${middleName}` : ""} ${lastName}`;
    }
    return "";
};

export const selectUserEmail = (state: StateType<typeof Reducers>) =>
    state.user?.email;

export const selectUserId = (state: StateType<typeof Reducers>) =>
    state.user?.id;

export const selectAccountId = (state: StateType<typeof Reducers>) =>
    state.user?.accountId;

export const selectExternalDdaId = (state: StateType<typeof Reducers>) =>
    state.user?.externalDdaId;

export const selectPublicToken = (state: StateType<typeof Reducers>) =>
    state.user?.publicToken;

export const selectPayerId = (state: StateType<typeof Reducers>) =>
    state.auth?.payerId;

export const selectFspId = (state: StateType<typeof Reducers>) =>
    state.auth?.fspId;

export const selectIAVToken = (state: StateType<typeof Reducers>) =>
    state.auth?.iavToken;

export const selectError = (state: StateType<typeof Reducers>) => state.error;

export const selectPlaidHandler = (state: StateType<typeof Reducers>) =>
    state.plaidHandler;

export const selectSelectionAccounts = (state: StateType<typeof Reducers>) =>
    state.selectionAccounts;

export const selectSelectionAccount = (
    state: StateType<typeof Reducers>,
    id: string
): Optional<ExtDdaAccount> => {
    const externalAccounts = selectSelectionAccounts(state) as ExtDdaAccount[];

    return externalAccounts.find(
        (externalAccount) =>
            externalAccount.id === id || externalAccount.accountNumber === id
    );
};

export const selectSessionExpireSeconds = (state: StateType<typeof Reducers>) =>
    state.fspConfig?.sessionExpireSeconds;
