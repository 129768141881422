import * as R from "ramda";

export const defaultFonts = {
  default: {
    name: "FreightSans Pro, sans-serif",
    url: "/fonts/stylesheet.css",
  },
  mono: {
    name: "Rift, sans-serif",
    url: "/fonts/stylesheet.css",
  },
};

const commaWithOptionalSpaces = /\s*,\s*/;

const splitFontNames = R.pipe(
  R.propOr("", "name"),
  R.split(commaWithOptionalSpaces)
);

const getFontFamilies = R.pipe(
  R.values,
  R.chain(splitFontNames),
  R.uniq
);

const getFontUrls = R.pipe(
  R.values,
  R.pluck("url"),
  R.uniq
);

export const getFontProperties = customFonts => {
  const fonts = {
    ...defaultFonts,
    ...customFonts,
  };

  return {
    fontFamilies: getFontFamilies(fonts),
    fontUrls: getFontUrls(fonts),
    fontNames: R.map(R.prop("name"), fonts),
  };
};
