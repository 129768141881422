import { put, call, takeEvery, takeLatest } from "redux-saga/effects";
import * as R from "ramda";

import i18n from "i18n";
import { setFspConfig } from "state/actions/fspConfig";
import { setProviderInfo } from "state/actions/provider";
import { AuthRequest, AuthResponse } from "types";
import { setUser } from "state/actions/user";
import {
  AUTHENTICATE,
  authenticateSuccess,
  authenticateError,
  LOGOUT,
  authenticating,
} from "state/actions/auth";

import { getIAVServer, getSdk, wrapSaga } from "./utils";
import { PayloadAction } from "typesafe-actions/dist/type-helpers";
import { IAV_NEW } from "consts";
import { initAnalytics } from "analytics";

export const authIAVNewUri = "/auth/iav_new";
export const authIAVUri = "/auth/iav";

function* populate(response: AuthResponse) {
  const { token, user, providerInfo, iavToken, fspConfig, verifiedAccount } =
    response;
  if (fspConfig.i18n) {
    R.mapObjIndexed(
      (bundle, language) =>
        i18n.addResourceBundle(language, "default", bundle, true, true),
      fspConfig.i18n
    );
  }

  yield put(setFspConfig(fspConfig));

  yield put(setUser(user));
  yield put(setProviderInfo(providerInfo));
  yield put(authenticateSuccess({ ...token, iavToken, verifiedAccount }));
}

export const auth = wrapSaga(function* ({
  payload,
}: PayloadAction<string, AuthRequest>) {
  try {
    initAnalytics();
    const {
      ssoToken,
      type,
      fspId,
      payerId,
      userId,
      accountId,
      extDdaId,
      useNew = false,
    } = payload;

    yield put(authenticating());

    const iavServer = yield getIAVServer();

    const sdk = yield getSdk();
    // Clear previous api token in sdk
    sdk.clearCache();

    let response: any;

    if (type === IAV_NEW || useNew) {
      response = yield call(iavServer.auth, authIAVNewUri, {
        ssoToken,
        type,
        fspId,
        payerId,
        userId,
        extDdaId,
      });
    } else {
      response = yield call(
        iavServer.post,
        authIAVUri,
        {
          accountId,
        },
        ssoToken
      );
      response.iavToken = ssoToken;
    }
    yield call(populate, response);
  } catch (exception) {
    yield put(authenticateError());
    throw exception;
  }
});

function* logout() {
  const sdk = yield getSdk();
  yield sdk.clearCache();
}

function* authSaga() {
  yield takeLatest(AUTHENTICATE, auth);
  yield takeEvery(LOGOUT, logout);
}

export default authSaga;
