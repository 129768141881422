import React, { useCallback } from "react";

import Dialog from "common/Dialog";
import Typography from "@material-ui/core/Typography";
import { getErrorMessage } from "utils";
import { withTrackError } from "analytics";

const ErrorDialog = ({
  title,
  logo,
  menuTitle,
  error,
  onAction,
  t,
  classes,
}) => {
  const message = error.number
    ? getErrorMessage({ error, t })
    : t(`error.${error.messageKey}`);
  const handleClose = useCallback(() => {
    onAction({ ...error, message });
  }, [error, message, onAction]);

  return (
    <Dialog
      title={title}
      logo={logo}
      menuTitle={menuTitle}
      onClose={handleClose}
      actionLabel={t("button.done")}
      onAction={handleClose}
      classes={classes}
      confirmBeforeExit={false}
    >
      <Typography color="inherit" variant="h6">
        {message}
      </Typography>
    </Dialog>
  );
};

export default withTrackError(ErrorDialog);
