import {
  SAGA_IN_PROGRESS_DELETE,
  SAGA_IN_PROGRESS_FINISH,
  SAGA_IN_PROGRESS_START,
} from "../actions/sagasInProgress";

export type SagaInProgress = {
  isRunning: boolean;
  isError?: boolean;
  data?: object;
};
export type SagasInProgressState = {
  [key: string]: SagaInProgress;
};
export const initialState = {};
export default function sagasInProgress(
  state: SagasInProgressState = initialState,
  {
    type,
    saga,
    data,
    isError,
  }: {
    type: string;
    saga: string;
    data?: object | undefined;
    isError?: boolean;
  }
) {
  const sagaName = saga;
  const sagaState = state[sagaName] || {};
  switch (type) {
    case SAGA_IN_PROGRESS_START:
      return {
        ...state,
        [sagaName]: { ...sagaState, isRunning: true },
      };

    case SAGA_IN_PROGRESS_FINISH:
      return {
        ...state,
        [sagaName]: { ...sagaState, isRunning: false, isError, data },
      };

    case SAGA_IN_PROGRESS_DELETE: {
      return { ...state, [sagaName]: {} };
    }
    default:
      return state;
  }
}
