/**
 * Detects if it's running inside a web view in Android or iOS.
 */
export default function useIsWebView() {
  const userAgent = window.navigator.userAgent.toLowerCase();
  const isiOSWebView =
    /iphone|ipod|ipad/.test(userAgent) && !/safari/.test(userAgent);
  const isAndroidWebView = userAgent.includes("wv");
  return isiOSWebView || isAndroidWebView;
}
