import React from "react";
import { createRoot } from "react-dom/client";
import { drop } from "ramda";
import qs from "qs";
import { AppProps } from "./types";
import App from "./App";
import configureStore from "./state/configureStore";

const { PROXY_ENV } = require("pzEnv");

const params = qs.parse(drop(1, window.location.search));

const onError = (error: any) => alert(error);
const onSuccess = (accounts: any) => alert(accounts);

const appProps: AppProps = {
  ssoToken: params.ssoToken,
  userId: params.userId,
  payerId: params.payerId,
  fspId: params.fspId,
  type: params.type,
  env: params.env || PROXY_ENV,
  adminUserId: params.adminUserId,
  initCount: 1,
  onError,
  onSuccess,
};

const store = configureStore(appProps.env, appProps.adminUserId);

const root = createRoot(document.getElementById("root")!);
root.render(<App store={store} {...appProps} />);
