import { combineReducers } from "redux";
import fspConfigReducer from "./fspConfigReducer";
import authReducer from "./authReducer";
import userReducer from "./userReducer";
import providerInfoReducer from "./providerInfoReducer";
import errorReducer from "./errorReducer";
import plaidHandlerReducer from "./plaidHandlerReducer";
import institutionReducer from "./institutionReducer";
import selectionAccountsReducer from "./selectionAccountsReducer";
import sagasInProgress from "./sagasInProgressReducer";

export default combineReducers({
  fspConfig: fspConfigReducer,
  auth: authReducer,
  user: userReducer,
  providerInfo: providerInfoReducer,
  error: errorReducer,
  plaidHandler: plaidHandlerReducer,
  institution: institutionReducer,
  selectionAccounts: selectionAccountsReducer,
  sagasInProgress,
});
