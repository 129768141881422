import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import List from "@material-ui/core/List";
import MUIDialog from "@material-ui/core/Dialog";
import SkeletonLoading from "common/SkeletonLoading";
import { Formik } from "formik";
import * as R from "ramda";
import track from "react-tracking";

import Dialog from "common/Dialog";
import useMedia from "hooks/useMedia";
import {
  selectInstitution,
  selectFspId,
  selectUserId,
  selectPayerId,
} from "state/selectors";
import { addExternalAccounts } from "state/actions/externalAccount";
import { logTrustlyEvent } from "state/actions/trustly";

import Account from "./Account";
import ErrorDialog from "./ErrorDialog";
import useStyles from "./useStyles";
import { SUCCESS_IAV_STATUS } from "../consts";
import {
  dateFormat,
  getCurrentUTCDate,
} from "../integration/IAVProviderIntegration";
import { trackSubmitAddExtDda } from "analytics";

const AccountSelectionModal = ({
  retrievedAccounts = [],
  error,
  onSuccess,
  onError,
  multi = false,
  tracking,
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const classes = useStyles();
  const { name, logo } = useSelector(selectInstitution);

  const fspId = useSelector(selectFspId);
  const payerId = useSelector(selectPayerId);
  const userId = useSelector(selectUserId);

  const mobile = !useMedia(["(min-width: 40em)"], [true], false);

  const handleOnSubmit = (values, { setSubmitting }) => {
    setSubmitting(true);
    tracking.trackEvent({ event: trackSubmitAddExtDda });
    dispatch(addExternalAccounts({ onSuccess, setSubmitting, ...values }));
  };

  return (
    <MUIDialog
      disableScrollLock
      open
      classes={mobile ? undefined : { paper: classes.paperDesktop }}
      fullScreen={mobile}
      fullWidth={mobile}
    >
      {error ? (
        <ErrorDialog
          error={error}
          t={t}
          menuTitle={t("iav.selectAccountTitle")}
          title={name}
          logo={logo}
          onAction={onError}
          classes={classes}
        />
      ) : retrievedAccounts.length > 0 ? (
        <Formik
          initialValues={retrievedAccounts.reduce(
            (values, { id, alreadyLinked, accountNumber }) => ({
              ...values,
              [id || accountNumber]: multi && !alreadyLinked,
            }),
            {}
          )}
          onSubmit={handleOnSubmit}
        >
          {({ isValid, handleSubmit, values, isSubmitting }) => {
            fspId &&
              payerId &&
              dispatch(
                logTrustlyEvent({
                  fspId: fspId,
                  userId: userId,
                  payerId: payerId,
                  requestType: "IAV",
                  startAt: dateFormat(getCurrentUTCDate()),
                  status: SUCCESS_IAV_STATUS,
                  transaction: JSON.stringify({
                    multi: multi,
                    isValid: isValid,
                    values: values,
                    isSubmitting: isSubmitting,
                  }),
                })
              );
            return (
              <Dialog
                classes={classes}
                open
                menuTitle={t("iav.selectAccountTitle")}
                title={name}
                logo={logo}
                onClose={onSuccess}
                confirmBeforeExit
                onAction={handleSubmit}
                disabled={
                  (!multi && !isValid) ||
                  !R.includes(true, R.values(values)) ||
                  isSubmitting
                }
                submitting={isSubmitting}
                actionLabel={t("button.next")}
              >
                <List>
                  {retrievedAccounts.map((retrievedAccount) => (
                    <Account
                      key={retrievedAccount.id}
                      account={retrievedAccount}
                      disabled={
                        (multi && !R.isNil(retrievedAccount.alreadyLinked)) ||
                        isSubmitting
                      }
                      multi={multi}
                    />
                  ))}
                </List>
              </Dialog>
            );
          }}
        </Formik>
      ) : (
        <SkeletonLoading classes={classes} title={name} logo={logo} />
      )}
    </MUIDialog>
  );
};

export default track()(AccountSelectionModal);
