import axios from "axios";
import * as R from "ramda";
import { HEADER_AUTHORIZATION } from "consts";

export const formatBearerAuthentication = (token: string) => `Bearer ${token}`;

const { environments } = require("pzEnv");

type RequestOptions = {
  headers?: {
    Authorization: string;
  };
};

const defaultOptions = (token?: string) => {
  const options: RequestOptions = {};
  if (token) {
    const headerAuthorization = formatBearerAuthentication(token);
    if (headerAuthorization) {
      options.headers = { [HEADER_AUTHORIZATION]: headerAuthorization };
    }
  }
  return options;
};

export const getUri = (uri: string, env: string) => `${getHost(env)}${uri}`;

export const getHost = (env: string = "proxy") => {
  if (env.indexOf("http") !== -1) {
    return env;
  }
  if (process.env.NODE_ENV !== "production") {
    return R.prop("host")(environments["proxy"]);
  }
  return R.prop("host")(environments[env]);
};

export const createIAVServerClient = (env: string, ssoClient = axios) => {
  const host = getHost(env);
  return {
    get: (uri: string, token: string, options = {}) =>
      ssoClient
        .get(`${host}${uri}`, { ...defaultOptions(token), ...options })
        .then((res) => res.data),

    post: (uri: string, data: any, token: string, options = {}) =>
      ssoClient
        .post(`${host}${uri}`, data, { ...defaultOptions(token), ...options })
        .then((res) => res.data),
    auth: (uri: string, data: any, token: string, options = {}) =>
      ssoClient
        .post(`${host}${uri}`, data, { ...defaultOptions(token), ...options })
        .then((res) => {
          const { data, headers } = res;
          return { ...data, iavToken: headers._token };
        }),
  };
};
