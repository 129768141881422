// Header Authorization
export const HEADER_AUTHORIZATION = "Authorization";

export const A2A_MICRO_SERVICE = "a2a";
export const ACTIVE_DDA_STATUS = "active";
export const MICRO_PENDING_DDA_STATUS = "microPending";

export const ON = "on";
export const OFF = "off";

export const AMOUNT_FORMAT = "$0,0.00";

export const IAV_NEW = "iav_new";
export const IAV = "iav";
export const IAV_API = "iav_api";
export const IAV_MICRO = "iav_micro";
export const IAV_REFRESH = "iav_refresh";
export const MICRODEPOSIT = "microdeposit";

export const PENDING_AUTOMATIC_VERIFICATION_STATUS =
  "pending_automatic_verification";
export const PENDING_MANUAL_VERIFICATION_STATUS = "pending_manual_verification";
export const MANUALLY_VERIFIED_VERIFICATION_STATUS = "manually_verified";
export const FAILED_TO_VERIFY = "FAILED_TO_VERIFY";
export const TRUSTLY_FAILED_TO_VERIFY = "TRUSTLY_FAILED_TO_VERIFY";

export const DESKTOP_WIDTH = 360;
export const DESKTOP_HEIGHT = 536;

export const PZ_IAV_ID = "pz_iav";

export const TRUSTLY_IAV_PROVIDER = "trustly";

export const SUCCESS_IAV_STATUS = "success";
export const EXIT_IAV_STATUS = "exit";
export const ERROR_IAV_STATUS = "error";
