import { useEffect, useRef } from "react";
import { logout } from "state/actions/auth";
import { userInteraction } from "state/actions/user";
import { isAuthenticated, selectSessionExpireSeconds } from "state/selectors";
import { differenceInSeconds } from "date-fns";
import { useDispatch, useSelector } from "react-redux";

const events = ["mousedown", "keydown", "touchstart", "visibilitychange"];

const timeInMillis = (time) => time * 1000;

const defaultDelay = 60;

const idleTracker = ({ timeout, delay, logout, userInteraction }) => ({
  clearTimeout() {
    if (this.timeoutId) {
      clearTimeout(this.timeoutId);
      delete this.timeoutId;
    }
  },
  resetTimeout() {
    if (timeout) {
      this.clearTimeout();
      this.startTime = new Date();
      userInteraction();
      this.timeoutId = setTimeout(() => {
        logout();
      }, timeInMillis(timeout));
    }
  },
  onEvent(event) {
    if (timeout) {
      const diff = differenceInSeconds(new Date(), this.startTime);
      if (diff >= timeout) {
        // Handle the case where computer comes back from sleep mode
        // and invokes one of the events.
        this.clearTimeout();
        logout();
      } else if (diff >= delay && event.type !== "visibilitychange") {
        this.resetTimeout();
      }
    }
  },
  startTime: new Date(),
  timeoutId: undefined,
});

export default function useIdleTimeout({
  enabled = true,
  delay = defaultDelay,
}) {
  const dispatch = useDispatch();
  const authenticated = useSelector(isAuthenticated);
  const sessionExpireSeconds = useSelector(selectSessionExpireSeconds);

  const shouldTrack = enabled && authenticated && !!sessionExpireSeconds;

  const trackerRef = useRef(null);

  trackerRef.current = idleTracker({
    timeout: sessionExpireSeconds,
    delay,
    logout: () => {
      dispatch(logout());
    },
    userInteraction: () => {
      dispatch(userInteraction());
    },
  });

  useEffect(() => {
    const trackerRefCurrent = trackerRef.current;
    const handleEvent = (event) => {
      trackerRefCurrent.onEvent(event);
    };
    if (shouldTrack) {
      trackerRefCurrent.resetTimeout();
      events.forEach((event) => {
        window.addEventListener(event, handleEvent, true);
      });
    }
    return () => {
      if (shouldTrack) {
        trackerRefCurrent.clearTimeout();
        events.forEach((event) => {
          window.removeEventListener(event, handleEvent, true);
        });
      }
    };
  }, [shouldTrack]);
}
