import { createStore, applyMiddleware } from "redux";
import createSagaMiddleware from "redux-saga";
import { composeWithDevTools } from "redux-devtools-extension/developmentOnly";

import { getUri, createIAVServerClient } from "./utils";

import { UNAUTHORIZED, LOGOUT } from "./actions/auth";
import appReducer from "./reducers";
import saga from "./sagas";

const createBrowserClient = require("payrailz-js-client-sdk");

const isClearAction = (type: string) =>
  type === UNAUTHORIZED || type === LOGOUT;

const removePlaidIframe = () => {
  const iframes: any = document.querySelectorAll("[id^='plaid-link-iframe']");
  if (iframes && iframes.length > 0) {
    for (const iframe of iframes) {
      iframe.parentNode.removeChild(iframe);
    }
  }
  // Plaid add this temporary id to html and not removing it for mobile
  const plaidLinkTemporaryId = "plaid-link-temporary-id";
  const element = document.getElementById(plaidLinkTemporaryId);
  if (element) {
    element.removeAttribute("id");
  }
};

const configureStore = (env: string, adminUserId: string, httpClient?: Function, externalSsoServerClient?: Function) => {
  const sdk = createBrowserClient({ httpClient, authHost: getUri("/auth", env)});
  const iavServer = createIAVServerClient(env, externalSsoServerClient ? externalSsoServerClient() : undefined);

  const sagaMiddleware = createSagaMiddleware({ context: { sdk, iavServer, adminUserId } });

  const composeEnhancers = composeWithDevTools;

  const rootReducer = (state: any, action: any) => {
    if (isClearAction(action.type)) {
      const { fspConfig, plaidHandler } = state;
      // Clear plaid iframe created from Plaid.create()
      if (plaidHandler) {
        removePlaidIframe();
      }
      if (window.Trustly) {
        window.Trustly.destroy();
      }
      state = {
        auth: { authenticated: false },
        fspConfig,
      };
    }
    return appReducer(state, action);
  };

  const store = createStore(
    rootReducer,
    composeEnhancers(applyMiddleware(sagaMiddleware))
  );

  sagaMiddleware.run(saga);

  return store;
};

export default configureStore;
