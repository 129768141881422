import { SagaInProgress } from "../reducers/sagasInProgressReducer";

export const SAGA_NOT_RUNNING: SagaInProgress = { isRunning: false };

export const selectSagaState = (
  sagaName: string | undefined | null,
  state: any
) => {
  return (sagaName && state.sagasInProgress[sagaName]) || {};
};

export const selectIsSagaInProgress = (sagaName: string) => (state: any) =>
  !!selectSagaState(sagaName, state)?.isRunning;
