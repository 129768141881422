import React, { Component } from "react";

import WebFont from "webfontloader";

const getFontConfigForTheme = theme => {
  if (theme.typekitId) {
    return {
      typekit: {
        id: theme.typekitId
      }
    };
  }

  if (theme.fontUrls) {
    if (!theme.fontFamilies) {
      console.warn("Please provide both fontUrls and the list of fontFamilies");
      return {};
    }

    return {
      custom: {
        families: theme.fontFamilies,
        urls: theme.fontUrls
      }
    };
  }
};

class FontLoader extends Component {
  static defaultProps = {
    activeStyle: {
      display: "flex",
      flex: 1
    }
  };

  state = {
    fontsLoaded: false
  };

  componentDidMount() {
    const { theme, fontLoadTimeout = 2000 } = this.props;
    const fontConfig = getFontConfigForTheme(theme);

    if (fontConfig) {
      WebFont.load({
        ...fontConfig,
        timeout: fontLoadTimeout,
        active: () => {
          this.setState({
            fontsLoaded: true
          });
        },
        inactive: () => {
          // Render the screen if fonts aren't loaded within the timeout
          this.setState({
            fontsLoaded: true
          });
        }
      });
    } else {
      this.setState({
        fontsLoaded: true
      });
    }
  }

  render() {
    return (
      <div
        style={
          this.state.fontsLoaded
            ? this.props.activeStyle
            : {
                ...this.props.activeStyle,
                visibility: "hidden"
              }
        }
      >
        {this.props.children}
      </div>
    );
  }
}

export default FontLoader;
