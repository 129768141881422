export const DEFAULT_ENV = "production";
export const PROXY_ENV = "proxy";

export const environments = {
  sandbox: {
    host: "https://sandboxiav.payrailztest.com",
  },
  sandbox2: {
    host: "https://sandbox2iav.payrailztest.com",
  },
  development: {
    host: "https://dev1iav.payrailzdev.com",
  },
  development2: {
    host: "https://dev2iav.payrailzdev.com",
  },
  development3: {
    host: "https://dev3iav.payrailzdev.com",
  },
  qa1: {
    host: "https://qa1iav.payrailzdev.com",
  },
  qa2: {
    host: "https://qa2iav.payrailzdev.com",
  },
  imp1: {
    host: "https://imp1iav.payrailztest.com",
  },
  perf: {
    host: "https://perfiav.payrailzdev.com",
  },
  training: {
    host: "https://trainingiav.payrailztest.com",
  },
  conv: {
    host: "https://conviav.payrailztest.com",
  },
  dr: {
    host: "https://driav.payrailzdr.com",
  },
  uat1: {
    host: "https://uat1iav.payrailztest.com",
  },
  local: {
    host: "http://localhost:5002",
  },
  localsecure: {
    host: "https://localhost",
  },
  production: {
    host: "https://iav.payrailzprod.com",
  },
  proxy: {
    host: "",
  },
};
