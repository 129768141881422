import React from "react";
import MaterialCircularProgress from "@material-ui/core/CircularProgress";
import Dialog from "common/Dialog";

const SkeletonLoading = ({ classes, title, logo, menuTitle }) => (
  <Dialog title={title} logo={logo} menuTitle={menuTitle} classes={classes}>
    <MaterialCircularProgress
      color="inherit"
      className={classes.progress}
      disableShrink
    />
  </Dialog>
);

export default SkeletonLoading;
