import React from "react";
import MUIButton from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(() => ({
  progress: {
    bottom: 0,
    left: 0,
    margin: "auto",
    position: "absolute",
    right: 0,
    top: 0,
  },
}));

const Button = ({
  children,
  disabled = false,
  submitting = false,
  ...props
}) => {
  const classes = useStyles();
  return (
    <MUIButton disabled={disabled} {...props}>
      {submitting && (
        <CircularProgress
          className={classes.progress}
          color="inherit"
          size={24}
        />
      )}
      {children}
    </MUIButton>
  );
};

export default Button;
