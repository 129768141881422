export const SAGA_IN_PROGRESS_START = "SAGA_IN_PROGRESS_START";
export const SAGA_IN_PROGRESS_FINISH = "SAGA_IN_PROGRESS_FINISH";
export const SAGA_IN_PROGRESS_DELETE = "SAGA_IN_PROGRESS_DELETE";

type ReduxAction = {
  type: string;
};

export type IdentifiedSagaAction = {
  sagaId?: string;
};

export type SagaInProgressAction = ReduxAction & {
  saga: string;
};

export type FinishSagaInProgressAction = SagaInProgressAction & {
  data?: object;
  isError?: boolean;
};

export const startSagaInProgress = (
  saga: string,
): SagaInProgressAction => ({
  type: SAGA_IN_PROGRESS_START,
  saga,
});

export const finishSagaInProgress = (
  saga: string,
  data: object | undefined = undefined,
  isError: boolean = false
): FinishSagaInProgressAction => ({
  type: SAGA_IN_PROGRESS_FINISH,
  saga,
  data,
  isError,
});

export const deleteSagaInProgress = (saga: string): SagaInProgressAction => ({
  type: SAGA_IN_PROGRESS_DELETE,
  saga,
});
