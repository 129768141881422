import { useCallback } from "react";
import * as R from "ramda";
import ReactGA from "react-ga";
import track, { useTracking } from "react-tracking";

// App
export const APP = "sso-ui";

// Page
export const PAGE_PAGE = "page";
export const ERROR_PAGE = "error";
export const CONFIRMATION_PAGE = "confirmation";

// Event
export const PLAID_EVENT = "Plaid";
export const ADD_EXT_DDA_EVENT = "AddExtDda";
export const PAGEVIEW_EVENT = "pageview";

export const VIEW_ACTION = "View";
export const SUBMIT_ACTION = "Submit";
export const CANCEL_ACTION = "Cancel";

export const trackView = (event) => `${event}.${VIEW_ACTION}`;
export const trackSubmit = (event) => `${event}.${SUBMIT_ACTION}`;
export const trackCancel = (event) => `${event}.${CANCEL_ACTION}`;

export const trackViewPlaid = trackView(PLAID_EVENT);
export const trackViewAddExtDda = trackView(ADD_EXT_DDA_EVENT);
export const trackSubmitAddExtDda = trackSubmit(ADD_EXT_DDA_EVENT);
export const trackCancelAddExtDda = trackCancel(ADD_EXT_DDA_EVENT);

const isAnalyticsEnabled = () =>
  process.env.REACT_APP_TRACKER_ENABLED === "true";

export const analyticsEnabled = isAnalyticsEnabled();

export const initAnalytics = () => {
  if (analyticsEnabled) {
    window.ssoPZTracking = {
      app: APP,
      page: PAGE_PAGE,
      event: trackViewPlaid,
      startTime: performance.now(),
    };

    ReactGA.initialize(process.env.REACT_APP_TRACKER_ID, {
      debug: process.env.REACT_APP_TRACKER_DEBUG === "true",
      gaOptions: {
        siteSpeedSampleRate: parseInt(
          `${process.env.REACT_APP_TRACKER_SAMPLE_RATE}`
        ),
      },
    });
  }
};

export const withTrackError = track({ page: ERROR_PAGE });
export const withTrackPage = track({ page: PAGE_PAGE });
export const withTrackConfirmation = track({ page: CONFIRMATION_PAGE });

export const trackEvent = (eventType) => (fn, to) => {
  const tracking = useTracking();
  return useCallback(
    (event) => {
      tracking.trackEvent({ event: eventType, to });
      fn(event);
    },
    [fn, to, tracking]
  );
};

export const dispatchAnalysis = (data) => {
  if (analyticsEnabled) {
    if (data.event === PAGEVIEW_EVENT && window.ssoPZTracking) {
      const elapsedTime = performance.now() - window.ssoPZTracking.startTime;
      const [category, action = window.ssoPZTracking.to] = R.split(
        ".",
        window.ssoPZTracking.event
      );
      const event = {
        category,
        action: R.defaultTo(category, action),
        value: Math.round(elapsedTime),
        label: data.page,
      };
      ReactGA.event(event);
      window.ssoPZTracking = null;
    } else if (data.event !== PAGEVIEW_EVENT) {
      data.startTime = performance.now();
      window.ssoPZTracking = data;
    }
  }
};

export const processAnalysis = (data) =>
  data.page ? { event: PAGEVIEW_EVENT, page: data.page } : null;

export const trackApp = track(
  { app: APP },
  {
    dispatch: dispatchAnalysis,
    process: processAnalysis,
  }
);
