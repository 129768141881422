import { SET_INSTITUTION } from "../actions/institution";
import createReducer from "./createReducer";
import { Institution } from "types";

const initialState: Institution = {};

export default createReducer<any>({
  initialState,
  successType: SET_INSTITUTION,
});
