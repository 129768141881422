import React, { useState } from "react";

import { useSelector } from "react-redux";
import { format as formatDate } from "date-fns/fp";

import { selectFspConfig } from "state/selectors";
import { withTrackPage } from "analytics";

import { IntegrationProps } from "./types";
import { MICRODEPOSIT, TRUSTLY_IAV_PROVIDER } from "../consts";
import PlaidIntegration from "./PlaidIntegration";
import TrustlyIntegrationMain from "./TrustlyIntegrationMain";

export const getCurrentUTCDate = () => {
  const date = new Date();

  return new Date(
    date.getUTCFullYear(),
    date.getUTCMonth(),
    date.getUTCDate(),
    date.getUTCHours(),
    date.getUTCMinutes(),
    date.getUTCSeconds()
  );
};

export const dateFormat = formatDate("yyyy-MM-dd HH:mm:ss");

const IAVProviderIntegration = ({
  accountId,
  type,
  ...props
}: IntegrationProps) => {
  const [startAt] = useState(dateFormat(getCurrentUTCDate()));
  const isTrustlyProvider =
    useSelector(selectFspConfig)?.a2aConfig?.iavProvider ===
    TRUSTLY_IAV_PROVIDER;

  const requestType = accountId ? MICRODEPOSIT : type;

  const Component = isTrustlyProvider
    ? TrustlyIntegrationMain
    : PlaidIntegration;

  return (
    <Component
      {...props}
      type={type}
      requestType={requestType}
      startAt={startAt}
    />
  );
};

export default withTrackPage(IAVProviderIntegration);
