import React from "react";
import { getIn } from "formik";

const getTouchedByName = (name, touched) => getIn(touched, name);

const getError = (name, { errors, touched }) =>
  name && errors && touched && getTouchedByName(name, touched)
    ? errors?.[name]
    : undefined;

const FieldComponent =
  (Component) =>
  ({
    field: { name, onBlur, value },
    form: { touched, errors, status, setFieldValue, values },
    format,
    onChange: externalOnChange,
    passSetFieldValue = false,
    ...props
  }) => {
    const helperText =
      status?.validate !== false
        ? getError(name, { errors, touched })
        : undefined;

    const error = !!helperText;

    const handleChange = (event, eventValue) => {
      let value = event;
      if (typeof eventValue !== "boolean" || !eventValue) {
        if (event?.target !== undefined) {
          value = event?.target?.value;
        }
        if (format && value) {
          value = format(value);
        }
        setFieldValue(name, value);
      } else {
        setFieldValue(name, value);
      }

      if (externalOnChange) {
        externalOnChange(value);
      }
    };
    return (
      <Component
        error={error}
        helperText={helperText}
        name={name}
        onBlur={onBlur}
        onChange={handleChange}
        {...props}
        value={value}
        {...(props.value && { value: props.value })}
        {...(passSetFieldValue && { setFieldValue })}
        values={values}
      />
    );
  };

export default FieldComponent;
