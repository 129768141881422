import themeBuilder from "./themeBuilder";
import { defaultFonts } from "./fonts";

export const defaultColor = {
  primaryColor: "#752289",
  primaryTextColor: "#ffffff",
  secondaryColor: "#B2FF3B",
  secondaryTextColor: "#000000",
};

export default themeBuilder({
  ...defaultColor,
  fonts: defaultFonts,
});
