import React, { useEffect, useState, useCallback } from "react";

import * as R from "ramda";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import useScript from "hooks/useScript";

import {
  receivePlaidToken,
  setPlaidHandler,
  logPlaidEvent,
} from "state/actions/plaid";
import { userInteraction } from "state/actions/user";
import {
  selectProviderInfo,
  selectSelectionAccounts,
  selectFspConfig,
  selectUserName,
  selectUserEmail,
  selectError,
  selectPlaidHandler,
  selectFspId,
  selectPayerId,
  selectUserId,
  selectPublicToken,
  selectExternalDdaId,
  selectAccountId,
  selectVerifiedAccount,
} from "state/selectors";
import AccountSelectionModal from "./AccountSelectionModal";
import { isMicroDeposit, isIAV } from "utils";
import { getUri } from "state/utils";
import { dispatchAnalysis, trackViewAddExtDda } from "analytics";
import {
  ERROR_IAV_STATUS,
  EXIT_IAV_STATUS,
  SUCCESS_IAV_STATUS,
  FAILED_TO_VERIFY,
} from "consts";

export const scriptId = "plaidIFrame";

export const TOO_MANY_VERIFICATION_ATTEMPS_ERROR =
  "TOO_MANY_VERIFICATION_ATTEMPTS";

const PlaidIntegration = ({
  env,
  onError,
  onSuccess,
  initCount,
  type,
  requestType,
  refresh,
  startAt,
}) => {
  const { t } = useTranslation();
  const plaidInfo = useSelector(selectProviderInfo);
  const fspConfig = useSelector(selectFspConfig);
  const userName = useSelector(selectUserName);
  const userEmail = useSelector(selectUserEmail);
  const accountPublicToken = useSelector(selectPublicToken);
  const retrievedAccounts = useSelector(selectSelectionAccounts);
  const error = useSelector(selectError);
  const plaidHandler = useSelector(selectPlaidHandler);
  const fspId = useSelector(selectFspId);
  const payerId = useSelector(selectPayerId);
  const userId = useSelector(selectUserId);
  const externalDdaId = useSelector(selectExternalDdaId);
  const accountId = useSelector(selectAccountId);
  const iav = isIAV(type);
  const verifiedAccount = useSelector(selectVerifiedAccount);

  const [processing, setProcessing] = useState(false);

  const dispatch = useDispatch();

  const handleSuccess = useCallback(
    (publicToken, metadata) => {
      dispatch(
        logPlaidEvent({
          fspId,
          userId,
          payerId,
          externalDdaId,
          accountId,
          error,
          metadata,
          requestType,
          startAt,
          publicToken,
          status: SUCCESS_IAV_STATUS,
        })
      );
      setProcessing(true);
      dispatchAnalysis({ event: trackViewAddExtDda });
      if (refresh === true) {
        onSuccess(true);
      } else {
        const { account: origAccount, accounts } = metadata;
        const microDeposit = isMicroDeposit(accounts[0].verification_status);

        // instant auth verification_status is null
        const account = origAccount?.verification_status ? origAccount : null;
        metadata.account = account;
        const params = { publicToken, metadata, type, microDeposit };
        if (!iav && microDeposit) {
          onError({ message: t("error.MICRO_DEPOSIT_NOT_SUPPORTED") });
        } else {
          if (account?.id || microDeposit) {
            params.onSuccess = onSuccess;
          }
          dispatch(receivePlaidToken(params));
        }
      }
    },
    [
      dispatch,
      onError,
      onSuccess,
      t,
      type,
      iav,
      setProcessing,
      error,
      requestType,
      startAt,
      refresh,
      accountId,
      externalDdaId,
      fspId,
      payerId,
      userId,
    ]
  );

  const handleEvent = useCallback(() => {
    dispatch(userInteraction());
  }, [dispatch]);
  const handleExit = useCallback(
    (error, metadata) => {
      if (error) {
        dispatch(
          logPlaidEvent({
            fspId,
            userId,
            payerId,
            externalDdaId,
            accountId,
            error,
            metadata,
            requestType,
            startAt,
            status: ERROR_IAV_STATUS,
          })
        );
        if (error.error_code === TOO_MANY_VERIFICATION_ATTEMPS_ERROR) {
          onError({ errorCode: FAILED_TO_VERIFY });
        }
        onError();
      } else {
        dispatch(
          logPlaidEvent({
            fspId,
            userId,
            payerId,
            externalDdaId,
            accountId,
            error,
            metadata,
            requestType,
            startAt,
            status: EXIT_IAV_STATUS,
          })
        );
        onSuccess();
      }
    },
    [
      dispatch,
      onError,
      onSuccess,
      startAt,
      requestType,
      accountId,
      externalDdaId,
      fspId,
      payerId,
      userId,
    ]
  );

  const handleLoadError = useCallback(() => {
    onError({ message: t("error.unableToLoadScript") });
  }, [onError, t]);

  const { publicKey, environment, product, linkScript, plaidEnv } = R.defaultTo(
    {},
    plaidInfo
  );

  const handleScriptLoad = useCallback(() => {
    const { fspName } = R.defaultTo({}, fspConfig);
    let microDepositParams = {};
    if (iav) {
      microDepositParams = {
        user: {
          legalName: userName,
          emailAddress: userEmail,
        },
        webhook: getUri(
          `/hooks/fsps/${fspId}/payers/${payerId}/users/${userId}`,
          env
        ),
      };
    }
    if (!plaidHandler && !verifiedAccount) {
      const newPlaidHandler = window.Plaid.create({
        clientName: fspName,
        env: env === "production" ? environment : plaidEnv || environment,
        key: publicKey,
        onSuccess: handleSuccess,
        onEvent: handleEvent,
        onExit: handleExit,
        token: accountPublicToken,
        product: [product],
        ...microDepositParams,
      });
      dispatch(setPlaidHandler(newPlaidHandler));
    }
  }, [
    dispatch,
    env,
    environment,
    fspConfig,
    fspId,
    handleEvent,
    handleExit,
    handleSuccess,
    payerId,
    plaidHandler,
    product,
    publicKey,
    accountPublicToken,
    iav,
    userEmail,
    userId,
    userName,
    plaidEnv,
    verifiedAccount,
  ]);

  useEffect(() => {
    if (verifiedAccount) {
      onSuccess([verifiedAccount]);
    }
  }, [verifiedAccount, onSuccess]);

  useScript(linkScript, scriptId, handleScriptLoad, handleLoadError);

  useEffect(() => {
    if (linkScript && plaidHandler && retrievedAccounts.length === 0) {
      plaidHandler.open();
    }
  }, [linkScript, plaidHandler, retrievedAccounts, initCount]);

  if (error || retrievedAccounts.length > 0 || processing) {
    return (
      <AccountSelectionModal
        retrievedAccounts={retrievedAccounts}
        error={error}
        onError={onError}
        onSuccess={onSuccess}
        multi={iav}
      />
    );
  }

  return <div />;
};

export default PlaidIntegration;
