import { makeStyles } from "@material-ui/core/styles";

import { DESKTOP_HEIGHT, DESKTOP_WIDTH } from "consts";

const useStyles = makeStyles((theme) => ({
  paperDesktop: {
    width: DESKTOP_WIDTH,
    height: DESKTOP_HEIGHT,
  },
  titleContainer: {
    marginBottom: theme.spacing(3),
  },
  title: {
    color: theme.custom.textColor,
    marginBottom: theme.spacing(1),
  },
  action: {
    margin: theme.spacing(2, 4, 4),
  },
  button: {
    height: 56,
    fontSize: 20,
  },
  progress: {
    bottom: 0,
    left: 0,
    margin: "auto",
    position: "absolute",
    right: 0,
    top: 0,
  },
}));

export default useStyles;
