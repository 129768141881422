export type CreateReducerParam<T> = {
  initialState?: T | null;
  successType: string;
  clearType?: string | null;
};

// This is for single entity
export default function createReducer<T>({
  initialState = null,
  successType,
  clearType
}: CreateReducerParam<T>) {
  return (
    state = initialState,
    { type, payload }: { type: string; payload: T }
  ) => {
    if (type === successType) {
      return payload;
    } else if (clearType && type === clearType) {
      return initialState;
    } else {
      return state;
    }
  };
}
