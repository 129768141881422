import React from "react";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  CircularProgress,
  IconButton,
  Typography,
  Divider,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { makeStyles, Theme } from "@material-ui/core/styles";

type LoadingDialogProps = {
  loading: boolean;
  onClose: () => void;
  title: string;
  message: string;
};

const useStyles = makeStyles((theme: Theme) => ({
  dialog: {
    borderRadius: 15,
  },
  title: {
    fontSize: 15,
    textAlign: "center",
    textTransform: "uppercase",
    fontWeight: 700,
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(3),
    top: 12,
  },
  content: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    minHeight: 300,
    width: "100%",
    maxWidth: 400,
    margin: "auto",
    padding: `${theme.spacing(5)}px ${theme.spacing(3)}px`,
  },
  message: {
    marginTop: theme.spacing(4),
  },
}));

const LoadingDialog = ({
  loading,
  onClose,
  title,
  message,
}: LoadingDialogProps) => {
  const classes = useStyles();

  return (
    <Dialog
      open={loading}
      onClose={onClose}
      classes={{ paper: classes.dialog }}
      onBackdropClick={() => false}
      onEscapeKeyDown={() => false}
      aria-labelledby="loading-dialog-title"
    >
      <DialogTitle id="loading-dialog-title">
        <Typography className={classes.title}>{title}</Typography>
        {onClose && (
          <IconButton
            aria-label="Close"
            aria-labelledby="close-dialog-button"
            className={classes.closeButton}
            onClick={onClose}
            size="small"
          >
            <CloseIcon />
          </IconButton>
        )}
      </DialogTitle>
      <Divider />
      <DialogContent className={classes.content}>
        <CircularProgress
          aria-busy={loading}
          aria-describedby="loading-dialog-message"
        />
        <Typography
          variant="subtitle1"
          align="center"
          className={classes.message}
          id="loading-dialog-message"
        >
          {message}
        </Typography>
      </DialogContent>
    </Dialog>
  );
};

export default LoadingDialog;
