import React, {
  useCallback,
  useRef,
  useEffect,
  useState,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import qs from "qs";
import useScript from "hooks/useScript";
import {
  selectProviderInfo,
  selectSelectionAccounts,
  selectFspId,
  selectUserId,
  selectPayerId,
  selectExternalDdaId,
  selectInstitution,
} from "state/selectors";
import LoadingDialog from "./LoadingDialog";
import AccountSelectionModal from "./AccountSelectionModal";
import {
  receiveTransaction,
  RECEIVED_TRANSACTION,
  logTrustlyEvent,
} from "state/actions/trustly";
import { selectSagaState } from "state/selectors/sagasInProgress";
import useIsWebView from "hooks/useIsWebView";
import {
  ERROR_IAV_STATUS,
  SUCCESS_IAV_STATUS,
  FAILED_TO_VERIFY,
  TRUSTLY_FAILED_TO_VERIFY,
  EXIT_IAV_STATUS,
} from "../consts";
import { isIAV, isMicroDepositRequestType } from "../utils";
import { ExtDdaAccount, TrustlyInfo } from "../types";
export const scriptId = "trustlyIFrame";

const MICRO_DEPOSIT_VERIFICATION_FAILED = "5";

type Error = {
  errorCode?: string;
  message?: string;
};
export type TrustlyIntegrationProps = {
  env: string;
  onError: (error?: Error) => void;
  onSuccess: (extDdas?: Array<ExtDdaAccount>) => void;
  initCount: number;
  type: string;
  refresh: boolean;
  startAt: string;
  requestType: string;
};

export const isAccountVerified = (transaction: string) => {
  // Use regular expression to find the verified status
  const match = transaction.match(/&payment\.account\.verified=(true|false)/);
  if (match && match[1]) {
    return match[1] === "true";
  }
  return false; // If no match is found, assume account is not verified
};

declare global {
  interface Window {
    Trustly: {
      establish: Function;
      addPanelListener: Function;
      destroy: Function;
      proceedToChooseAccount: Function;
    };
  }
}

const CANCEL_REQUEST = "cancel";
const SUBMIT_REQUEST = "return";

type TrustlyResponse = Event & {
  type: string;
  transactionId: string;
  page: string;
  data: typeof CANCEL_REQUEST | typeof SUBMIT_REQUEST;
};

const stringifyObject = (object: any) => {
  if (!object || typeof object === "string") {
    return object;
  }
  try {
    return JSON.stringify(object);
  } catch (exception) {
    return "unable to stringify object";
  }
};

const TrustlyIntegration = ({
  type,
  startAt,
  onError,
  onSuccess,
  requestType,
}: TrustlyIntegrationProps) => {
  const iav = isIAV(type);
  const historyLengthRef = useRef(window.history.length);
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const trustlyInfo = useSelector(selectProviderInfo) as TrustlyInfo;
  const fspId = useSelector(selectFspId);
  const payerId = useSelector(selectPayerId);
  const userId = useSelector(selectUserId);
  const externalDdaId = useSelector(selectExternalDdaId);
  const institution = useSelector(selectInstitution);
  const externalAccounts = useSelector(selectSelectionAccounts);
  const sagaState = useSelector((state) =>
    selectSagaState(RECEIVED_TRANSACTION, state)
  );
  const establishRef = useRef<boolean>(false);

  const isWebView = useIsWebView();

  const dispatch = useDispatch();

  // ** history solution **
  const handleHistory = (initialHistoryLength: number) => {
    if (window.location.pathname !== "blank") {
      const historyFix = initialHistoryLength - window.history.length;
      if (historyFix < 0) {
        window.history.go(historyFix);
      }
    }
  };

  const handleSuccess = useCallback(
    (extDdas: Array<ExtDdaAccount>) => {
      setTimeout(() => {
        handleHistory(historyLengthRef.current);
        setIsLoading(false);
      }, 0);
      extDdas && extDdas.length > 0 ? onSuccess(extDdas) : onSuccess();
    },
    [onSuccess]
  );

  useEffect(() => {
    if (sagaState?.data && !sagaState?.isError) {
      const { microDeposit, accounts } = sagaState.data;
      if (microDeposit) {
        dispatch(
          logTrustlyEvent({
            fspId: fspId!,
            userId: userId!,
            payerId: payerId!,
            externalDdaId,
            institution,
            requestType: type,
            startAt,
            status: SUCCESS_IAV_STATUS,
            transaction: JSON.stringify(accounts),
          })
        );
        handleSuccess(accounts);
      }
    }
  }, [
    handleSuccess,
    sagaState,
    dispatch,
    fspId,
    externalDdaId,
    institution,
    payerId,
    startAt,
    type,
    userId,
  ]);

  const handleLightBoxSuccess = useCallback(
    (data?: string) => {
      if (data) {
        dispatch(
          logTrustlyEvent({
            fspId: fspId!,
            userId: userId!,
            payerId: payerId!,
            externalDdaId,
            institution,
            requestType: type,
            startAt,
            status: SUCCESS_IAV_STATUS,
            transaction: data,
          })
        );
        dispatch(receiveTransaction({ transaction: data, type }));
      } else {
        handleSuccess([]);
      }
    },
    [
      dispatch,
      handleSuccess,
      type,
      fspId,
      externalDdaId,
      institution,
      payerId,
      startAt,
      userId,
    ]
  );

  const CANCEL_URL = "#cancel";
  const RETURN_URL = "#success";

  const handleScriptLoad = useCallback(() => {
    if (!establishRef.current) {
      establishRef.current = true;
      const mobileOptions = isWebView
        ? {
            metadata: {
              urlScheme: `${trustlyInfo.pzIAVScheme ?? "pziavoauth"}://`,
              integrationContext: "InAppBrowser",
            },
          }
        : {};

      window.Trustly?.establish({
        ...trustlyInfo?.data,
        returnUrl: RETURN_URL,
        cancelUrl: CANCEL_URL,
        ...mobileOptions,
      });
      window.Trustly?.addPanelListener(
        (command: string, obj: TrustlyResponse) => {
          switch (command) {
            case "event":
              switch (obj.type) {
                case "new_location":
                  obj.preventDefault();
                  obj.stopPropagation();
                  if (obj.data?.indexOf(RETURN_URL) === 0) {
                    const transaction = obj.data.substring(
                      RETURN_URL.length + 1
                    );

                    dispatch(
                      logTrustlyEvent({
                        fspId: fspId!,
                        userId: userId!,
                        payerId: payerId!,
                        externalDdaId,
                        institution,
                        requestType: type,
                        startAt,
                        transaction,
                        status: SUCCESS_IAV_STATUS,
                      })
                    );
                    handleLightBoxSuccess(transaction);

                  } else if (obj.data.indexOf(CANCEL_URL) === 0) {
                    const transaction = obj.data.substring(
                      CANCEL_URL.length + 1
                    );
                    const { status } = qs.parse(transaction);
                    if (
                      isMicroDepositRequestType(requestType) &&
                      status === MICRO_DEPOSIT_VERIFICATION_FAILED
                    ) {
                      dispatch(
                        logTrustlyEvent({
                          fspId: fspId!,
                          userId: userId!,
                          payerId: payerId!,
                          externalDdaId,
                          institution,
                          requestType: type,
                          startAt,
                          status: ERROR_IAV_STATUS,
                          transaction,
                        })
                      );
                      onError({
                        errorCode: FAILED_TO_VERIFY,
                      });
                    } else {
                      dispatch(
                        logTrustlyEvent({
                          fspId: fspId!,
                          userId: userId!,
                          payerId: payerId!,
                          externalDdaId,
                          requestType: type,
                          startAt,
                          status: EXIT_IAV_STATUS,
                          transaction,
                          institution,
                        })
                      );
                    }
                    handleLightBoxSuccess();
                  } else {
                    dispatch(
                      logTrustlyEvent({
                        fspId: fspId!,
                        userId: userId!,
                        payerId: payerId!,
                        externalDdaId,
                        requestType: type,
                        startAt,
                        status: ERROR_IAV_STATUS,
                        transaction: stringifyObject(obj),
                        transactionId: obj.transactionId,
                        institution,
                      })
                    );
                    onError();
                  }
                  break;
                case "close":
                  const transaction = obj.data.substring(CANCEL_URL.length + 1);
                  const { status } = qs.parse(transaction);
                  if (status === MICRO_DEPOSIT_VERIFICATION_FAILED) {
                    dispatch(
                      logTrustlyEvent({
                        fspId: fspId!,
                        userId: userId!,
                        payerId: payerId!,
                        externalDdaId,
                        institution,
                        requestType: type,
                        startAt,
                        status: ERROR_IAV_STATUS,
                        transaction: stringifyObject({ command, transaction }),
                        transactionId: obj.transactionId,
                      })
                    );
                    onError({
                      errorCode: TRUSTLY_FAILED_TO_VERIFY,
                    });
                    break;
                  }
                  if (obj.page === "error") {
                    dispatch(
                      logTrustlyEvent({
                        fspId: fspId!,
                        userId: userId!,
                        payerId: payerId!,
                        externalDdaId,
                        institution,
                        requestType: type,
                        startAt,
                        status: ERROR_IAV_STATUS,
                        transaction: stringifyObject(obj),
                        transactionId: obj.transactionId,
                      })
                    );
                    onError();
                  }
                  break;
                default:
                  dispatch(
                    logTrustlyEvent({
                      fspId: fspId!,
                      userId: userId!,
                      payerId: payerId!,
                      externalDdaId,
                      institution,
                      requestType: type,
                      startAt,
                      transaction: stringifyObject(obj),
                      transactionId: obj.transactionId,
                      status: SUCCESS_IAV_STATUS,
                    })
                  );
              }
              break;
            default:
              dispatch(
                logTrustlyEvent({
                  fspId: fspId!,
                  userId: userId!,
                  payerId: payerId!,
                  externalDdaId,
                  institution,
                  requestType: type,
                  startAt,
                  transaction: stringifyObject({ command, obj }),
                  transactionId: obj?.transactionId,
                  status: SUCCESS_IAV_STATUS,
                })
              );
          }
        }
      );
    }
  }, [
    dispatch,
    trustlyInfo,
    handleLightBoxSuccess,
    type,
    startAt,
    onError,
    requestType,
    externalDdaId,
    fspId,
    institution,
    payerId,
    userId,
    isWebView,
  ]);

  const handleLoadError = useCallback(() => {
    onError({ message: t("error.unableToLoadScript") });
  }, [onError, t]);

  useScript(
    trustlyInfo?.clientEndpoint,
    scriptId,
    handleScriptLoad,
    handleLoadError
  );

  const handleOauthProceedMessage = useCallback((event: MessageEvent) => {
    if (event?.data === "trustlyProceedToChooseAccount") {
      window.Trustly?.proceedToChooseAccount?.();
    }
  }, []);

  useEffect(() => {
    window.addEventListener("message", handleOauthProceedMessage, false);

    return () => {
      window.removeEventListener("message", handleOauthProceedMessage);
    };
  }, [handleOauthProceedMessage]);

  useEffect(() => {
    if (externalAccounts && externalAccounts.length > 0) {
      setIsLoading(false);
    }
  }, [
    externalAccounts,
    isLoading,
    handleLightBoxSuccess,
  ]);

  if ((isLoading && !sagaState?.isError) || sagaState?.isRunning) {
    return (
      <LoadingDialog
        title={t("loadingDialog.title")}
        message={t("loadingDialog.message")}
        loading={true}
        onClose={() => handleSuccess([])}
      />
    );
  } else if (
    sagaState?.isError ||
    (externalAccounts && externalAccounts.length > 0)
  ) {
    return (
      <AccountSelectionModal
        error={sagaState?.isError ? sagaState.data : undefined}
        onError={onError}
        onSuccess={handleSuccess}
        retrievedAccounts={externalAccounts}
        multi={iav}
      />
    );
  }
  return <div />;
};

export default TrustlyIntegration;
