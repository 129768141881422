import { call, put } from "redux-saga/effects";
import { UNAUTHORIZED } from "../actions/auth";
import {
  finishSagaInProgress,
  startSagaInProgress,
} from "../actions/sagasInProgress";

import { getError } from "./utils";

type UXTransactionAction = {
  type: string;
  sagaId?: string | number;
  buttonValue: string;
};

type SagaFn = (...args: any[]) => any;

function uxTransactionSaga(sagaFn: SagaFn) {
  return function* wrappedSaga({ type, ...data }: UXTransactionAction) {
    const sagaId: string = type;
    yield put(startSagaInProgress(sagaId));
    try {
      const response: object = yield call(sagaFn, data);
      yield put(finishSagaInProgress(sagaId, response));
    } catch (e) {
      if (e.response && e.response.status === 403) {
        yield put({ type: UNAUTHORIZED });
      } else {
        const error = getError(e);
        yield put(finishSagaInProgress(sagaId, error, true));
      }
    }
  };
}

export default uxTransactionSaga;
