import { ExtDdaAccount } from "types";
import { ADD_SELECTION_ACCOUNTS } from "../actions/selectionAccounts";
import createReducer from "./createReducer";

const initialState: ExtDdaAccount[] = [];

export default createReducer<ExtDdaAccount[]>({
  initialState,
  successType: ADD_SELECTION_ACCOUNTS,
});
