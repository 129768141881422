import { call, throttle, select, put } from "redux-saga/effects";

import { USER_INTERACTION } from "state/actions/user";
import {
  authenticateSuccess,
} from "state/actions/auth";
import { selectIAVToken } from '../selectors';
import { getIAVServer } from "./utils";

const serverKeepAliveUri = "/auth/alive";

export const KEEP_ALIVE_INTERVAL = 60 * 1000; // 1 minutes

export function* keepAlive() {
  try {
    const iavToken = yield select(selectIAVToken);
    if (iavToken) {
      const ssoServer = yield getIAVServer();
      const response: any = yield call(ssoServer.auth, serverKeepAliveUri, {}, iavToken);
      const { token, iavToken: updatedIavToken } = response;
      yield put(authenticateSuccess({ ...token, iavToken: updatedIavToken }));
    }
  } catch (exception) {
    // Keep alive request failed. Ignore.
  }
}

function* keepAliveSaga() {
  yield throttle(KEEP_ALIVE_INTERVAL, USER_INTERACTION, keepAlive);
}

export default keepAliveSaga;
