import React from "react";
import { Trans, useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/core/styles";
import MUIDialog from "@material-ui/core/Dialog";

import Dialog from "common/Dialog";
import useMedia from "hooks/useMedia";
import useStyles from "./useStyles";
import Typography from "@material-ui/core/Typography";

const useContentStyles = makeStyles((theme) => ({
  codeLabel: {
    margin: theme.spacing(1, 0),
    textTransform: "uppercase",
  },
  bottomInstruction: {
    margin: theme.spacing(2, 0),
  },
}));

type TrustlyMicroDepositInstructionProps = {
  onExit: () => void;
  onContinue: () => void;
};

const TrustlyMicroDepositInstruction = ({
  onExit,
  onContinue,
}: TrustlyMicroDepositInstructionProps) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const contentClasses = useContentStyles();
  const mobile = !useMedia(["(min-width: 40em)"], [true], false);

  return (
    <MUIDialog
      disableScrollLock
      open
      classes={mobile ? undefined : { paper: classes.paperDesktop }}
      fullScreen={mobile}
      fullWidth={mobile}
    >
      <Dialog
        classes={classes}
        title="Reminder"
        open
        onClose={onExit}
        onAction={onContinue}
        actionLabel={t("button.next")}
        submitting={false}
        disabled={false}
        confirmBeforeExit
      >
        <Typography variant="body1" align="center">
          <Trans i18nKey="iav.mcdInstruction1"></Trans>
        </Typography>
        <Typography variant="body1" align="center">
          <Trans i18nKey="iav.mcdInstruction2"></Trans>
        </Typography>
        <Typography
          className={contentClasses.codeLabel}
          variant="body1"
          align="center"
        >
          <Trans i18nKey="iav.mcdExampleCodeLabel"></Trans>
        </Typography>
        <Typography variant="h4" align="center">
          <Trans i18nKey="iav.mcdExampleCode"></Trans>
        </Typography>
        <Typography
          className={contentClasses.bottomInstruction}
          variant="body1"
          align="center"
        >
          <Trans i18nKey="iav.mcdInstruction3"></Trans>
        </Typography>
      </Dialog>
    </MUIDialog>
  );
};

export default TrustlyMicroDepositInstruction;
