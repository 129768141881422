import React, { Component } from "react";
import { connect } from "react-redux";

import {
  MuiThemeProvider,
  StylesProvider,
  createGenerateClassName,
} from "@material-ui/core/styles";

import { mergeDeepRight } from "ramda";
import { mix } from "polished";

import payrailzTheme, { defaultColor } from "themes/payrailz";
import FontLoader from "./FontLoader";

import themeBuilder from "./themeBuilder";

import { selectFspConfig } from "state/selectors";
import createTheme from "./createTheme";
import { selectInstitution } from "../state/selectors";
import { isValidColor } from "../utils";

const defaultGenerateClassName = createGenerateClassName({
  disableGlobal: true,
  productionPrefix: "iav",
});

const white = "#ffffff";

const makeAlt = (color, opacity = 0.9, mixWith = white) =>
  mix(opacity, mixWith, color);

const createMuiTheme = (theme) => {
  const background = theme.background ?? "#fff";
  const textColor = theme.background ? theme.primaryTextColor : "#000";
  const secondaryTextColor = theme.background
    ? theme.secondaryTextColor
    : "#000";
  return createTheme({
    custom: {
      primaryTextColor: theme.primaryTextColor,
      secondaryTextColor,
      mono: theme.fonts.mono,
      textColor,
    },
    palette: {
      primary: {
        main: theme.background ? theme.background : theme.primaryColor,
      },
      secondary: {
        main: theme.secondaryColor,
      },
      error: {
        main: "#ff0000",
      },
      background: {
        default: theme.background
          ? "#fafafa"
          : makeAlt(background, 0.1, "#000"),
        paper: background,
      },
      text: {
        primary: textColor,
      },
    },
    overrides: mergeDeepRight(
      {
        MuiDialogActions: {
          root: {
            justifyContent: "center",
          },
        },
        MuiButton: {
          containedPrimary: {
            backgroundColor: makeAlt(
              theme.background ? theme.background : theme.primaryColor,
              0.12
            ),
            color: theme.primaryTextColor,
            textTransform: "none",
            "&:disabled": {
              color: theme.primaryTextColor,
              opacity: 0.5,
            },
          },
        },
        MuiBackdrop: {
          root: {
            backgroundColor: "rgba(109, 109, 109, 0.4)",
          },
        },
      },
      theme.iavOverrides || {}
    ),
    typography: mergeDeepRight(
      {
        fontFamily: theme.fontFamilies,
        h5: {
          fontSize: 20,
        },
        h6: {
          fontSize: 18,
        },
        body1: {
          fontSize: 14,
        }
      },
      theme.typography || {}
    ),
  });
};
class ThemeContainer extends Component {
  static defaultProps = {
    theme: {},
  };

  render() {
    const {
      theme,
      fontLoadTimeout = 2000,
      generateClassName = defaultGenerateClassName,
      children,
    } = this.props;

    const materialTheme = createMuiTheme(theme);
    return (
      <StylesProvider generateClassName={generateClassName}>
        <MuiThemeProvider theme={materialTheme}>
          <FontLoader
            theme={theme}
            activeStyle={{ flex: 1, display: "flex", flexDirection: "column" }}
            fontLoadTimeout={fontLoadTimeout}
          >
            {children}
          </FontLoader>
        </MuiThemeProvider>
      </StylesProvider>
    );
  }
}

const getTheme = (config, institution) =>
  config && config.theme
    ? themeBuilder({
        ...defaultColor,
        ...config.theme,
        ...(isValidColor(institution.primaryColor)
          ? { background: institution.primaryColor }
          : {}),
      })
    : payrailzTheme;

const mapStateToProps = (state) => ({
  theme: getTheme(selectFspConfig(state), selectInstitution(state)),
});

export default connect(mapStateToProps)(ThemeContainer);
