import React from "react";
import MUIDialog from "@material-ui/core/Dialog";
import MaterialCircularProgress from "@material-ui/core/CircularProgress";

import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(() => ({
  paper: {
    background: "transparent",
    boxShadow: "none",
    overflow: "hidden",
  },
  progress: {
    color: "#444444",
  },
}));

const Loading = () => {
  const classes = useStyles();
  return (
    <MUIDialog
      open
      classes={{
        paper: classes.paper,
      }}
    >
      <MaterialCircularProgress className={classes.progress} disableShrink />
    </MUIDialog>
  );
};

export default Loading;
