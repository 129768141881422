import { useState, useEffect } from "react";

const loadEvent = "load";
const errorEvent = "error";

const scriptElement = "script";

export default function useScript(src, scriptId, onScriptLoad, onScriptError) {
  // Keeping track of script loaded and error state
  const [state, setState] = useState({
    loaded: false,
    error: false,
  });

  useEffect(() => {
    if (src) {
      // No need to load if it has been loaded
      if (document.getElementById(scriptId)) {
        setState({
          loaded: true,
          error: false,
        });
        if (onScriptLoad) {
          onScriptLoad();
        }
      } else {
        let script = document.createElement(scriptElement);
        script.src = src;
        script.id = scriptId;
        script.async = true;
        setState({
          loaded: false,
          error: false,
        });

        const handleScriptLoad = () => {
          setState({
            loaded: true,
            error: false,
          });
          if (onScriptLoad) {
            onScriptLoad();
          }
        };

        const handleScriptError = () => {
          setState({
            loaded: true,
            error: true,
          });
          if (onScriptError) {
            onScriptError();
          }
        };

        script.addEventListener(loadEvent, handleScriptLoad);
        script.addEventListener(errorEvent, handleScriptError);

        document.body.appendChild(script);

        return () => {
          script.removeEventListener(loadEvent, handleScriptLoad);
          script.removeEventListener(errorEvent, handleScriptError);
        };
      }
    }
  }, [src, onScriptError, onScriptLoad, scriptId]);
  return [state.loaded, state.error];
}
