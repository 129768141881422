import { action } from "typesafe-actions";
import { TrustlyLog } from "types";

export const LOG_TRUSTLY_EVENT = "LOG_TRUSTLY_EVENT";

export const logTrustlyEvent = (payload: TrustlyLog) =>
  action(LOG_TRUSTLY_EVENT, payload);

export const RECEIVED_TRANSACTION = "RECEIVED_TRANSACTION";

type ReceiveTransactionParams = {
  transaction: string;
  type: string;
};

export const receiveTransaction = (data: ReceiveTransactionParams) => {
  return action(RECEIVED_TRANSACTION, data);
};
