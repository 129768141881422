import React from "react";
import { Field } from "formik";
import * as R from "ramda";
import { makeStyles, Theme } from "@material-ui/core/styles";
import ListItem from "@material-ui/core/ListItem";
import DoneIcon from "@material-ui/icons/Done";
import ListItemText from "@material-ui/core/ListItemText";
import FieldComponent from "common/FieldComponent";
import { ExtDdaAccount } from "types";
import { formatAmount } from "utils";
import { withTrackPage } from "analytics";

const getTypographyProps = (selected: boolean, align: string) => {
  const props: any = {
    align,
  };
  if (!selected) {
    props.color = "inherit";
  }
  return props;
};

type ItemProps = {
  primaryText: any;
  secondaryText: any;
  typographyPrimaryProps: any;
  typographySecondaryProps: any;
};

const Item = ({
  primaryText,
  secondaryText,
  typographyPrimaryProps,
  typographySecondaryProps,
}: ItemProps) => (
  <ListItemText
    primary={primaryText}
    primaryTypographyProps={{ variant: "h5", ...typographyPrimaryProps }}
    secondary={secondaryText}
    secondaryTypographyProps={{ variant: "h6", ...typographySecondaryProps }}
  />
);

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    borderRadius: 4,
    "&$selected": {
      backgroundColor: theme.palette.background.default,
      color: theme.custom.secondaryTextColor,
      "&:hover": {
        backgroundColor: theme.palette.background.default,
      },
    },
    "&$disabled": {
      cursor: "default",
    },
    marginBottom: theme.spacing(1),
    cursor: "pointer",
  },
  selected: {},
  disabled: {},
}));

type AccountProps = {
  account: ExtDdaAccount;
  value: boolean;
  setFieldValue: any;
  name: string;
  classes: any;
  values: any;
  multi: boolean;
  disabled: boolean;
};

const leftAlign = "left";
const rightAlign = "right";

const Account = ({
  account,
  value,
  setFieldValue,
  name,
  values,
  multi,
  disabled,
}: AccountProps) => {
  const classes = useStyles();
  let onClick;

  if (!disabled) {
    onClick = () => {
      if (!multi) {
        const keys = R.keys(values);
        R.forEach((key) => {
          if (values[key]) {
            setFieldValue(key, false);
          }
        }, keys);
      }
      setFieldValue(name, !value);
    };
  }
  return (
    <ListItem
      alignItems="flex-start"
      classes={classes}
      selected={value}
      disabled={disabled}
      onClick={onClick}
    >
      <Item
        primaryText={account.nickName}
        secondaryText={`****${
          account.accountNumberLastFour ?? account.accountNbrMask
        }`}
        typographyPrimaryProps={getTypographyProps(value, leftAlign)}
        typographySecondaryProps={getTypographyProps(value, leftAlign)}
      />
      <Item
        primaryText={value ? <DoneIcon fontSize="small" /> : <div>&nbsp;</div>}
        secondaryText={
          account.availBalance ? formatAmount(account.availBalance) : undefined
        }
        typographyPrimaryProps={getTypographyProps(value, rightAlign)}
        typographySecondaryProps={getTypographyProps(value, rightAlign)}
      />
    </ListItem>
  );
};

const AccountSelect = FieldComponent(Account);

const AccountField = ({
  account,
  multi,
  disabled,
}: {
  account: ExtDdaAccount;
  multi: boolean;
  disabled: boolean;
}) => (
  <Field
    name={account.id || account.accountNumber}
    account={account}
    component={AccountSelect}
    multi={multi}
    disabled={disabled}
    passSetFieldValue
  />
);

export default withTrackPage(AccountField);
