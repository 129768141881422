import React from "react";
import { useTranslation } from "react-i18next";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import { Grid } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  container: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  button: {
    height: 56,
    fontSize: 20,
    marginTop: theme.spacing(2),
  },
}));

const ExitConfirmation = ({ onYes, onNo }) => {
  const { t } = useTranslation();
  const yesLabel = t("button.yesExit");
  const noLabel = t("button.noContinue");
  const classes = useStyles();
  return (
    <Grid
      classes={{ container: classes.container }}
      container
      direction="column"
      alignItems="center"
    >
      <Typography variant="h5" color="inherit" align="center">
        {t("exitConfirmationMessage")}
      </Typography>

      <Button
        variant="contained"
        color="primary"
        onClick={onYes}
        size="large"
        fullWidth
        aria-label={yesLabel}
        className={classes.button}
      >
        {yesLabel}
      </Button>
      <Button
        variant="contained"
        color="primary"
        onClick={onNo}
        size="large"
        fullWidth
        aria-label={noLabel}
        className={classes.button}
      >
        {noLabel}
      </Button>
    </Grid>
  );
};

export default ExitConfirmation;
