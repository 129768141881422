import { all, call } from "redux-saga/effects";
import authSaga from "./authSaga";
import plaidSaga from "./plaidSaga";
import keepAliveSaga from "./keepAliveSaga";
import trustlySaga from "./trustlySaga";
import uxTransactionSaga from "./uxTransactionSaga";
import externalAccountSaga from "./externalAccountSaga";

function* rootSaga() {
  yield all(
    [
      authSaga,
      plaidSaga,
      trustlySaga,
      keepAliveSaga,
      uxTransactionSaga,
      externalAccountSaga,
    ].map((saga) => call(saga))
  );
}

export default rootSaga;
